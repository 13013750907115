// Generated by Framer (91d32d4)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["dWLkxqp4C", "MdDOVKefK", "zMrAXK0z1"];

const serializationHash = "framer-CRseM"

const variantClassNames = {dWLkxqp4C: "framer-v-ijkrdi", MdDOVKefK: "framer-v-1ye8ngp", zMrAXK0z1: "framer-v-4w2rlf"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {delay: 0, duration: 0.4, ease: [0, 0, 1, 1], type: "tween"}};

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const humanReadableVariantMap = {Large: "zMrAXK0z1", Medium: "MdDOVKefK", Small: "dWLkxqp4C"}

const getProps = ({height, id, width, ...props}) => { return {...props, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "dWLkxqp4C"} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "dWLkxqp4C", transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Transition value={transition}><motion.div {...restProps} animate={variants} className={cx(serializationHash, ...sharedStyleClassNames, "framer-ijkrdi", className, classNames)} data-framer-name={"Small"} initial={variant} layoutDependency={layoutDependency} layoutId={"dWLkxqp4C"} onHoverEnd={() => setGestureState({isHovered: false})} onHoverStart={() => setGestureState({isHovered: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} onTapStart={() => setGestureState({isPressed: true})} ref={ref ?? ref1} style={{backgroundColor: "var(--token-006fdba6-9690-47f6-9eee-17e66a9c00aa, rgb(216, 80, 64))", ...style}} {...addPropertyOverrides({MdDOVKefK: {"data-framer-name": "Medium"}, zMrAXK0z1: {"data-framer-name": "Large"}}, baseVariant, gestureVariant)}/></Transition>
</LayoutGroup>)

});

const css = [".framer-CRseM[data-border=\"true\"]::after, .framer-CRseM [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-CRseM.framer-lkr2pc, .framer-CRseM .framer-lkr2pc { display: block; }", ".framer-CRseM.framer-ijkrdi { height: 50px; overflow: hidden; position: relative; width: 2px; }", ".framer-CRseM.framer-v-1ye8ngp.framer-ijkrdi { height: 150px; }", ".framer-CRseM.framer-v-4w2rlf.framer-ijkrdi { height: 500px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 50
 * @framerIntrinsicWidth 2
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"MdDOVKefK":{"layout":["fixed","fixed"]},"zMrAXK0z1":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 */
const FramerydjvmMqiA: React.ComponentType<Props> = withCSS(Component, css, "framer-CRseM") as typeof Component;
export default FramerydjvmMqiA;

FramerydjvmMqiA.displayName = "Timeline/Progress";

FramerydjvmMqiA.defaultProps = {height: 50, width: 2};

addPropertyControls(FramerydjvmMqiA, {variant: {options: ["dWLkxqp4C", "MdDOVKefK", "zMrAXK0z1"], optionTitles: ["Small", "Medium", "Large"], title: "Variant", type: ControlType.Enum}} as any)

addFonts(FramerydjvmMqiA, [])